<template>
  <section
    style="padding: 1rem;"
    class="main-promos"
  >

    <div>

      <div
        v-if="isLoadingPromos"
        class="grid-container"
      >
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </div>
      <div
        v-if="!isLoadingPromos"
        class="grid-container"
      >
        <CardPromo
          v-for="promo in promoList"
          :key="promo.id"
          :promo="promo"
        />
      </div>

      <b-alert
        variant="warning"
        :show="isNotFoundPromos"
      >
        <div class="alert-body text-center">
          <span> <strong>¡{{ this.$t('No records') }}!</strong> {{ this.$t('No promotions found to share') }}.</span>
        </div>
      </b-alert>
    </div>
    <!-- <b-alert variant="warning" :show="true">
      <div class="alert-body text-center">
        <span> <strong>¡ {{  $t('admin') }} !</strong> {{  $t('this area is for owners only') }}.</span>
      </div>
    </b-alert> -->

    <AcceptSocialPromos
      :isAcceptingSharePromotions="isAcceptingSharePromotions"
      :term-condition="termCondition"
      @set-share-social-promos="setShareSocialPromos"
    />

  </section>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'

import { utils } from '@/modules/owners/mixins/utils'
import CardPromo from '@/modules/owners/components/SocialPromos/CardPromo'
import CardSkeleton from '@/modules/owners/components/SocialPromos/CardSkeleton'
import AcceptSocialPromos from '@/modules/owners/components/SocialPromos/AcceptSocialPromos'
import { showAlertMessage } from '@/helpers/helpers'

export default {
  components: {
    CardPromo,
    CardSkeleton,
    AcceptSocialPromos,
  },
  mixins: [utils],

  data() {
    return {
      isLoadingPromos: false,
      promoList: [],
      isNotFoundPromos: false,
      userCanSharePromotions: false,
      responseOwnerCanSharePromotions: null,
      isAcceptingSharePromotions: false,
      termCondition: null,
    }
  },
  computed: {
    ...mapState('auth', ['user', 'myContracts', 'userAdmin']),
    ...mapGetters('auth', ['contractSelected']),
    idContract() {
      return this.contractSelected ? this.contractSelected.id : null
    },
    idLanguage() {
      return this.user.languageId ? this.user.languageId : 1
    },
  },
  async mounted() {
    await this.initOwnerCanSharePromotions()
  },
  methods: {

    ...mapActions('owners', ['getPromosOwner', 'getCansharePromosOwner', 'acceptPromosOwner', 'fetchTermsConditions']),
    async initOwnerCanSharePromotions() {
      const { data } = await this.getCansharePromosOwner({ idContract: this.idContract })

      this.userCanSharePromotions = (data !== null) ? data.canSharePromotions : false

      if (this.userCanSharePromotions) {
        await this.getPromotions()
      }

      if (!this.userCanSharePromotions) {
        this.termCondition = await this.fetchTermsConditions({ idTerm: 27, idLanguage: this.idLanguage, typeTerms: 3 })
        this.$root.$emit('bv::show::modal', 'acept-social-promos')
      }
    },
    async setShareSocialPromos() {
      this.isAcceptingSharePromotions = true

      await this.pushData('onClick', 'acceptToSharePromotions', 'acceptToSharePromotions', this.$route.name) // push a back del registro

      const { status, message } = await this.acceptPromosOwner({ idOwner: this.user.idOwner, idContract: this.idContract })

      if (status) {
        this.isAcceptingSharePromotions = false
        this.$root.$emit('bv::hide::modal', 'acept-social-promos')
        showAlertMessage('Ok', 'InfoIcon', message, 'success', 3000, 'bottom-right')
        // push a back del registro
        await this.pushData('onSubmit', 'acceptToSharePromotionsWasSuccessfull', 'acceptToSharePromotionsWasSuccessfull', this.$route.name)
        await this.getPromotions()
      }

      if (!status) {
        showAlertMessage('Error', 'InfoIcon', message, 'danger', 3000, 'bottom-right')
      }

      this.isAcceptingSharePromotions = false
    },
    async getPromotions() {
      this.isLoadingPromos = true
      this.isNotFoundPromos = false

      // si idContract es diferente de nulo
      if (this.idContract) {
        const { status, promos } = await this.getPromosOwner({ idContract: this.idContract, idLanguage: this.idLanguage })

        if (status) {
          this.promoList = promos
          // this.promoList = []

          this.promoList.forEach(promo => {
            promo.socialNetworks = promo.socialNetworks.map(network => network.toLowerCase()) // minusculas -> ["facebook", "twitter"]
          })
        }

        this.isNotFoundPromos = promos.length === 0
        // this.isNotFoundPromos = true
      }
      this.isLoadingPromos = false
    },
  },
}
</script>

<style scoped>

.main-promos{
  height: 100%;
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr; /* Una columna por defecto en pantallas móviles */
  gap: 10px;
  /* padding: 20px; */

    /* align-items: center; */
}

/* Estilos para pantallas grandes */
@media screen and (min-width: 640px) {
  .grid-container {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
}

</style>
