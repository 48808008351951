<template>
  <b-modal
    id="acept-social-promos"
    size="md"
    title="Aceptar"
    ok-only
    centered
    no-close-on-backdrop
    hide-header
    scrollable
    modal-bg-variant="custom-opacity"
  >

    <b-overlay :show="isAcceptingSharePromotions">
      <div class="modal-container">

        <div
          v-if="!!termCondition"
          class="promos-content-accept p-main"
          v-html="termCondition.content"
        />

      </div>
    </b-overlay>

    <template #modal-footer="{ }">
      <div class="w-100 d-flex justify-content-between">
        <button
          class="btn-promos-accepts"
          :disabled="isAcceptingSharePromotions"
          @click="setShareSocialPromos"
        >
          {{ $t('I want to use this service') }}
        </button>
        <button
          class="btn-promos-accepts"
          :disabled="isAcceptingSharePromotions"
          @click="closeModal"
        >
          {{ $t('I am not interested') }}
        </button>
      </div>
    </template>

  </b-modal>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'

import { utils } from '@/modules/owners/mixins/utils'

export default {
  mixins: [utils],
  props: {
    isAcceptingSharePromotions: {
      Type: Boolean,
      required: true,
    },
    termCondition: {
      Type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['contractSelected']),
    langLocale() {
      return this.$i18n.locale
    },
  },

  methods: {
    ...mapActions('owners', ['getPromosOwner']),
    ...mapActions('owners', ['acceptPromosOwner']),

    async closeModal() {
      this.$root.$emit('bv::hide::modal', 'acept-social-promos')
      await this.pushData('onClick', 'declineToSharePromotions', 'declineToSharePromotions', this.$route.name) // push a back del registro
    },

    setShareSocialPromos() {
      // validar si es admin desde mixin en utils
      if (this.isAdmin) {
        this.$root.$emit('bv::hide::modal', 'acept-social-promos')
        return this.$swal.fire({ icon: 'error', title: `${this.$t('Restricted Access')}`, html: `${this.$t('Text Restricted Access Admin')}` })
      }

      this.$emit('set-share-social-promos')
    },

  },

}
</script>
<style>
/* Estilo personalizado para quitar el border-radius del b-modal */

#acept-social-promos .modal-content {
  height: 30rem;
  border: 0.5px solid #6b6a6a;
  border-radius: 0!important;
  /* box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1); */
}

.promos-container-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-promos-accepts {
  background-color: #333; /* Gris oscuro */
  color: #fff; /* Texto blanco */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}

.btn-promos-accepts:disabled{
  opacity: 60%;
}

/* Estilos adicionales para pantallas más grandes */
@media (min-width: 768px) {
  .promos-container-buttons {
    flex-direction: row;
    justify-content: space-around;
  }

  .btn-promos-accepts {
    margin: 5px;
  }
}

.promos-content-accept {
  color: black;
}

.p-intro span {
  font-style: italic;
  font-weight: bold;
}

.p-main a{
  font-weight: bold;
}

/*
{ opacity: .3; background-color: rgb(0,0,0,.3); height: 100%; } modal <--cuadro gris (border) element { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); display: block; background: #999; width: 600px; height: 300px; padding: 5px; } inner modal <--cuadro blanco element { background: #fff; display: block; width: 100%; height: 100%; padding: 10px; text-align: justify; } clase para los nombres de marcas .hlgt { font-style: italic; font-weight: bold; } */

</style>
